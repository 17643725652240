.smat-user-avatar {
  border-radius: 50%;
}

.smat-user-avatar img {
  border: 3px solid;
  object-fit: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.smat-user-avatar span {
  font-size: 14px;
}

.dropdown-toggle.nav-link::after {
  display: none;
}

.nav-link {
  color: black
}

.nav-link-item {
  color: black;
  text-decoration: none !important;
}

.nav-link-item:hover {
  color: black !important;
}


.nav-app-links,
.nav-location-phone-avatar,
.nav-menus-items {
  display: none;
}

@media screen and (min-width: 992px) {

  .nav-app-links,
  .nav-location-phone-avatar,
  .nav-menus-items {
    display: flex;
  }

  #responsive-navbar-nav,
  .nav-location-phone-avatar-second {
    display: none !important;
  }

}
